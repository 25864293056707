(function () {
  'use strict';

  angular
    .module('events.vehicles')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('events.vehicles', {
        abstract: true,
        url: '/:eventId/vehicles',
        templateUrl: 'events/vehicles/vehicles.tpl.html',
        resolve: {
          event: function ($stateParams, Restangular) {
            return Restangular.one('events', $stateParams.eventId).get();
          }
        }
      })
      .state('events.vehicles.list', {
        url: '?q&p',
        params: {
          q: {
            value: '',
            squash: true
          },
          p: {
            value: '1',
            squash: true
          }
        },
        reloadOnSearch: false,
        templateUrl: 'events/vehicles/list/list.tpl.html',
        controller: 'VehiclesListCtrl',
        controllerAs: 'vm'
      })
    ;
  }
}());
